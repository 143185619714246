import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import instagramReducer from "./instagramReducer";
import menuReducer from "./menuReducer";
import modalReducer from "./modalReducers";
import orderReducer from "./orderReducer";
import tiktokReducer from "./tiktokReducer";
import utilsReducer from './utilsReducer';

const rootReducer = combineReducers({
  globalData: globalReducer,
  instagramData: instagramReducer,
  orderData: orderReducer,
  tiktokData: tiktokReducer,
  modalData: modalReducer,
  menuData: menuReducer,
  utilsData: utilsReducer,
});

export default rootReducer;
