import * as types from "../actionTypes";

const initalState = {
  instaModalState: false,
  instaMediaModalState: false,
  youtubeModalState: false,
  tiktokModalState: false,
  tiktokMediaModalState: false,
  facebookModalState: false,
  facebookVideoModalState: false,
  soundCloudModalState: false,
  orderSummaryModalState: false,
  paymentModalState: false,
  termsModalState: false,
  reviewModalState: false,
  retryPaymentModalState: false,
  maxPaymentModalState: false,
  tooltipState: false,
};

const modalReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.INSTAGRAM_MODAL:
      return { ...state, instaModalState: action.instaModalState };
    case types.ORDER_SUMMARY_MODAL:
      return {
        ...state,
        orderSummaryModalState: action.orderSummaryModalState,
      };
    case types.INSTAGRAM_MEDIA_MODAL:
      return { ...state, instaMediaModalState: action.instaMediaModalState };
    case types.PAYMENT_MODAL:
      return { ...state, paymentModalState: action.paymentModalState };
    case types.TERMS_MODAL:
      return { ...state, termsModalState: action.termsModalState };
    case types.YOUTUBE_MODAL:
      return { ...state, youtubeModalState: action.youtubeModalState };
    case types.TIKTOK_MODAL:
      return { ...state, tiktokModalState: action.tiktokModalState };
    case types.TIKTOK_MEDIA_MODAL:
      return { ...state, tiktokMediaModalState: action.tiktokMediaModalState };
    case types.FACEBOOK_MODAL:
      return { ...state, facebookModalState: action.facebookModalState };
    case types.FACEBOOK_VIDEO_MODAL:
      return {
        ...state,
        facebookVideoModalState: action.facebookVideoModalState,
      };
    case types.SOUNDCLOUD_MODAL:
      return {
        ...state,
        soundCloudModalState: action.soundCloudModalState,
      };
    case types.REVIEW_MODAL:
      return {
        ...state,
        reviewModalState: action.reviewModalState,
      };
    case types.RETRY_PAYMENT_MODAL:
      return {
        ...state,
        retryPaymentModalState: action.retryPaymentModalState,
      };
    case types.MAX_PAYMENT_MODAL:
      return {
        ...state,
        maxPaymentModalState: action.maxPaymentModalState,
      };
    case types.CUSTOM_TOOLTIP:
      return {
        ...state,
        tooltipState: action.tooltipState,
      };

    default:
      return { ...state };
  }
};

export default modalReducer;
