import { STOP_VIDEO } from '../actionTypes';

const initalState = {
  play: false,
};

const utilsReducer = (state = initalState, action) => {
  switch (action.type) {
    case STOP_VIDEO:
      return { ...state, play: action.payload };
    default:
      return { ...state };
  }
};

export default utilsReducer;
