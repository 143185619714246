exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comprar-comentarios-instagram-js": () => import("./../../../src/pages/comprar-comentarios-instagram.js" /* webpackChunkName: "component---src-pages-comprar-comentarios-instagram-js" */),
  "component---src-pages-comprar-curtidas-instagram-js": () => import("./../../../src/pages/comprar-curtidas-instagram.js" /* webpackChunkName: "component---src-pages-comprar-curtidas-instagram-js" */),
  "component---src-pages-comprar-visualizacoes-reels-js": () => import("./../../../src/pages/comprar-visualizacoes-reels.js" /* webpackChunkName: "component---src-pages-comprar-visualizacoes-reels-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-midias-sociais-js": () => import("./../../../src/pages/midias-sociais.js" /* webpackChunkName: "component---src-pages-midias-sociais-js" */),
  "component---src-pages-seguidores-gratis-js": () => import("./../../../src/pages/seguidores-gratis.js" /* webpackChunkName: "component---src-pages-seguidores-gratis-js" */),
  "component---src-pages-sub-politica-de-cookies-mdx": () => import("./../../../src/pages/sub/politica-de-cookies.mdx" /* webpackChunkName: "component---src-pages-sub-politica-de-cookies-mdx" */),
  "component---src-pages-sub-politica-de-privacidade-mdx": () => import("./../../../src/pages/sub/politica-de-privacidade.mdx" /* webpackChunkName: "component---src-pages-sub-politica-de-privacidade-mdx" */),
  "component---src-pages-sub-sobre-mdx": () => import("./../../../src/pages/sub/sobre.mdx" /* webpackChunkName: "component---src-pages-sub-sobre-mdx" */),
  "component---src-pages-sub-termos-e-condicoes-mdx": () => import("./../../../src/pages/sub/termos-e-condicoes.mdx" /* webpackChunkName: "component---src-pages-sub-termos-e-condicoes-mdx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

