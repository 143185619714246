import * as types from '../actionTypes';

const initalState = {
    mediaList: [],
    videos: [],
    pictures: [],
    igtvs: [],
    reels: [],
    userObject: null,
    cursor: "",
    userId: "",
    rhxGis: ""
}

const instagramReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.IG_SET_MEDIALIST:
            return { ...state, mediaList: [...action.mediaList] };
        case types.IG_SET_VIDEOS:
            return { ...state, videos: [...action.videos] };
        case types.IG_SET_PICTURES:
            return { ...state, pictures: [...action.pictures] };
        case types.IG_SET_IGTVS:
            return { ...state, igtvs: [...action.igtvs] };
        case types.IG_SET_REELS:
            return { ...state, reels: [...action.reels] };
        case types.IG_SET_USEROBJECT:
            return { ...state, userObject: { ...action.userObject } };
        case types.IG_SET_CURSOR:
            return { ...state, cursor: action.cursor };
        case types.IG_SET_USERID:
            return { ...state, userId: action.userId };
        case types.IG_SET_RHXGIS:
            return { ...state, rhxGis: action.rhxGis };
        default:
            return { ...state };
    }
};

export default instagramReducer;