import * as types from '../actionTypes';

const initalState = {
    isRecapchaLoaded: false
}

const globalReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.GLOBAL_SET_RECAPCHA_LOADED:
            return { ...state, isRecapchaLoaded: true };
        default:
            return { ...state };
    }
};

export default globalReducer;