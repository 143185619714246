import React from "react";
import { Provider } from "react-redux";

import store from "./configureStore";

// eslint-disable-next-line react/display-name,react/prop-types
const wrapWithProvider = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};

export default wrapWithProvider;
