import * as types from '../actionTypes';

const initalState = {
    videos: [],
    userObject: null,
}

const tiktokReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.TIKTOK_SET_VIDEOS:
            return { ...state, videos: [...action.videos] };
        case types.TIKTOK_SET_USEROBJECT:
            return { ...state, userObject: { ...action.userObject } };
        default:
            return { ...state };
    }
};

export default tiktokReducer;