// Instagram
export const IG_SET_MEDIALIST = 'IG_SET_MEDIALIST'
export const IG_SET_VIDEOS = 'IG_SET_VIDEOS'
export const IG_SET_PICTURES = 'IG_SET_PICTURES'
export const IG_SET_IGTVS = 'IG_SET_IGTVS'
export const IG_SET_REELS = 'IG_SET_REELS'
export const IG_SET_USEROBJECT = 'IG_SET_USEROBJECT'
export const IG_SET_CURSOR = 'IG_SET_CURSOR'
export const IG_SET_USERID = 'IG_SET_USERID'
export const IG_SET_RHXGIS = 'IG_SET_RHXGIS'

// Tiktok
export const TIKTOK_SET_VIDEOS = 'TIKTOK_SET_VIDEOS'
export const TIKTOK_SET_USEROBJECT = 'TIKTOK_SET_USEROBJECT'

// Order
export const ORDER_SET_LINK = 'ORDER_SET_LINK'
export const ORDER_SET_PRICE = 'ORDER_SET_PRICE'
export const ORDER_SET_PICTURE_URL = 'ORDER_SET_PICTURE_URL'
export const ORDER_SET_AMOUNT = 'ORDER_SET_AMOUNT'
export const ORDER_SET_SERVICE_NAME = 'ORDER_SET_SERVICE_NAME'
export const ORDER_SET_PRODUCT_TYPE = 'ORDER_SET_PRODUCT_TYPE'
export const ORDER_SET_SOURCE_NAME = 'ORDER_SET_SOURCE_NAME'
export const ORDER_SET_IDENTITY = 'ORDER_SET_IDENTITY'
export const ORDER_SET_FULL_SERVICE_NAME = 'ORDER_SET_FULL_SERVICE_NAME'
export const ORDER_SET_PARAMETER_DESC = 'ORDER_SET_PARAMETER_DESC'
export const ORDER_SET_IS_TERMS_CONFIRMED = 'ORDER_SET_IS_TERMS_CONFIRMED'
export const ORDER_SET_EMAIL = 'ORDER_SET_EMAIL'
export const ORDER_SET_FULL_NAME = 'ORDER_SET_FULL_NAME'
export const ORDER_SET_CPF = 'ORDER_SET_CPF'
export const ORDER_SET_PHONE = 'ORDER_SET_PHONE'
export const ORDER_SET_TOTAL = 'ORDER_SET_TOTAL'
export const ORDER_SET_CART_ID = 'ORDER_SET_CART_ID'
export const ORDER_SET_DISCOUNT = 'ORDER_SET_DISCOUNT'
export const ORDER_SET_IS_FREE = 'ORDER_SET_IS_FREE'
export const ORDER_SET_COUPON_CODE = 'ORDER_SET_COUPON_CODE'
export const INSTAGRAM_MODAL = 'INSTAGRAM_MODAL'
export const INSTAGRAM_MEDIA_MODAL = 'INSTAGRAM_MEDIA_MODAL'
export const YOUTUBE_MODAL = 'YOUTUBE_MODAL'
export const FACEBOOK_MODAL = 'FACEBOOK_MODAL'
export const FACEBOOK_VIDEO_MODAL = 'FACEBOOK_VIDEO_MODAL'
export const TIKTOK_MODAL = 'TIKTOK_MODAL'
export const TIKTOK_MEDIA_MODAL = 'TIKTOK_MEDIA_MODAL'
export const SOUNDCLOUD_MODAL = 'SOUNDCLOUD_MODAL'
export const ORDER_SUMMARY_MODAL = 'ORDER_SUMMARY_MODAL'
export const PAYMENT_MODAL = 'PAYMENT_MODAL'
export const TERMS_MODAL = 'TERMS_MODAL'
export const REVIEW_MODAL = 'REVIEW_MODAL'
export const RETRY_PAYMENT_MODAL = 'RETRY_PAYMENT_MODAL'
export const MAX_PAYMENT_MODAL = 'MAX_PAYMENT_MODAL'
export const CUSTOM_TOOLTIP = 'CUSTOM_TOOLTIP'
export const MOBILE_NAV = 'MOBILE_NAV'
export const SCROLLING = 'SCROLLING'
export const STOP_VIDEO = 'STOP_VIDEO'

// Global
export const GLOBAL_SET_RECAPCHA_LOADED = 'GLOBAL_SET_RECAPCHA_LOADED'
