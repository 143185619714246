import { createStore, applyMiddleware } from "redux"
import reduxImmutableStateInvariant from "redux-immutable-state-invariant"

import rootReducer from "./reducers/rootReducer"

const initialState = {}
const middleware = [reduxImmutableStateInvariant()]
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
)

export default store
