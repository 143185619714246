import * as types from "../actionTypes";

const initalState = {
  scrollingState: false,
  mobileNavState: false,
};

const menuReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.SCROLLING:
      return { ...state, scrollingState: action.scrollingState };
    case types.MOBILE_NAV:
      return { ...state, mobileNavState: action.mobileNavState };
    default:
      return { ...state };
  }
};

export default menuReducer;
