import * as types from '../actionTypes'

const initalState = {
  link: '',
  price: 0,
  total: 0,
  pictureUrl: '',
  amount: 0,
  serviceName: '',
  productType: '',
  sourceName: '',
  identity: '',
  fullServiceName: '',
  parameterDesc: '',
  isTermsConfirmed: true,
  email: '',
  fullName: '',
  cpf: '',
  phone: '',
  cartId: '',
  couponCode: '',
  discount: 0,
  isFree: false,
}

const orderReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.ORDER_SET_LINK:
      return { ...state, link: action.link }
    case types.ORDER_SET_PRICE:
      return { ...state, price: action.price }
    case types.ORDER_SET_PICTURE_URL:
      return { ...state, pictureUrl: action.pictureUrl }
    case types.ORDER_SET_AMOUNT:
      return { ...state, amount: action.amount }
    case types.ORDER_SET_SERVICE_NAME:
      return { ...state, serviceName: action.serviceName }
    case types.ORDER_SET_PRODUCT_TYPE:
      return { ...state, productType: action.productType }
    case types.ORDER_SET_SOURCE_NAME:
      return { ...state, sourceName: action.sourceName }
    case types.ORDER_SET_IDENTITY:
      return { ...state, identity: action.identity }
    case types.ORDER_SET_FULL_SERVICE_NAME:
      return { ...state, fullServiceName: action.fullServiceName }
    case types.ORDER_SET_PARAMETER_DESC:
      return { ...state, parameterDesc: action.parameterDesc }
    case types.ORDER_SET_IS_TERMS_CONFIRMED:
      return { ...state, isTermsConfirmed: action.isTermsConfirmed }
    case types.ORDER_SET_EMAIL:
      return { ...state, email: action.email }
    case types.ORDER_SET_FULL_NAME:
      return { ...state, fullName: action.fullName }
    case types.ORDER_SET_CPF:
      return { ...state, cpf: action.cpf }
    case types.ORDER_SET_PHONE:
      return { ...state, phone: action.phone }
    case types.ORDER_SET_TOTAL:
      return { ...state, total: action.total }
    case types.ORDER_SET_CART_ID:
      return { ...state, cartId: action.cartId }
    case types.ORDER_SET_COUPON_CODE:
      return { ...state, couponCode: action.couponCode }
    case types.ORDER_SET_DISCOUNT:
      return { ...state, discount: action.discount }
    case types.ORDER_SET_IS_FREE:
      return { ...state, isFree: action.isFree }
    default:
      return { ...state }
  }
}

export default orderReducer
